#client-slider .slick-slider {
    padding-top: 20px;
    padding-bottom: 20px;
   }

  #client-slider .slick-slider .slick-slide img{
    height: 100px ;
    width: 100%;
    max-width:200px;
    object-fit: contain; 
   }

   #client-slider .slick-slider .slick-slide div{
    width:200px;
   }
   