#projects_hero{
    height: 45vh;
    background-image: url('./images/projects_bg.jpg');
    background-size: cover;
    text-align: center;
    vertical-align:middle;
    padding-top:140px;
    color:var(--primary-blue-color);
    transition: background-image 2s;
}

#projects_hero  h1 {
    margin:auto;
    vertical-align:middle ;
   background-color: rgba(8,8,8,0.46) ;
   color: var(--primary-yellow-color);
   padding: 10px;
   box-shadow: 5px 5px 5px black ;
  
}

#projects_hero  h1:before{
    border: 0px;
}

.projects-submenu{
  padding: 0px;
  margin: 0px;
}

.projects_text p{
  font-family: 'PT Sans', sans-serif;
}
  
      div.gallery {
        overflow: hidden;
      }
      div.gallery img {
        width: 100%;
        height: 300px;
        transition: transform .7s; /* Animation */
        
      }
      div.gallery img:hover {
        transform: scale(1.1);
      }
      
      * {
        box-sizing: border-box;
      }
      
      .responsive {
        margin: 0 6px 10px;
        float: left;
        width: calc(33.333% - 12px);
      }
    
      @media only screen and (max-width: 1000px) {
        .responsive {
            width: calc(49.99999% - 12px);
            margin: 0 6px 10px;
        }
      }

      @media only screen and (max-width: 700px) {
        .responsive {
          width: 100%;
          margin: 0 6px;
        }
      }
      
      @media only screen and (max-width: 500px) {
        .responsive {
          width: 100%;
        }
      }
    

      .projects {
        margin-bottom: 40px
    }
    
    .projects .project {
        position: relative;
    }
    
    .projects .project.pattern {
        background-repeat: repeat;
        -webkit-background-size: unset;
        -moz-background-size: unset;
        background-size: unset
    }
    
    .image-container {
      position: relative;
      overflow: hidden;
    }
    /* .image-container img{
      filter: grayscale(95%);
    } */
    .hover-bar {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 40px; /* Set the height of the hover bar */
     background-color: #888;
     /* background-color: rgba(55, 73, 145, 0.9); */
     color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      opacity: 1; /* Initially hidden */
      transition: opacity 0.6s ease-in-out; /* Add a smooth transition effect */
    }
    
    .image-container:hover .hover-bar {
      opacity: 1; /* Show the hover bar on hover */

     background-color: rgba(238, 154, 8, 0.9);
    }

    .image-container:hover img{
      filter: grayscale(10%);
    }
    
    @media (max-width: 767px) {
        .projects .project {
            margin-bottom:30px
        }
    
        .projects:not(.postsBlock) {
            max-width: 360px
        }
    
        .projects.postsBlock .project {
            width: 48%;
            padding-top: 48%
        }
    }
    
    @media (max-width: 500px) {
        .projects {
            max-width:360px
        }
    
        .projects.postsBlock .project {
            width: 100%;
            padding-top: 100%
        }
    }
    

    .sub-menu {
      /* Your default styles for the sub-menu */
    }
    
    .sticky {
      padding-right: 0px;
      margin-left: 0 !important;
      right: 0;
      position: fixed;
      top: 0;
      margin-top: 95px;
      width: 100%;
      z-index: 1000; /* Adjust the z-index as needed */
      /* Add additional styles for the sticky state */
    }