:root{
  --primary-blue-color: #374991;
  --primary-yellow-color: #ee9a08;
  --secondary-gray-color: #b2b4b3;
  --secondary-light-gray-color: #ebedec;

}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--secondary-gray-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
