
h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
  font-family: 'Montserrat', sans-serif;
  color:var(--primary-blue-color);
}

h1 {
  font-weight: 100;
  text-transform: uppercase;
  color:var(--primary-blue-color);
}

a{
  font-family: 'PT Sans', sans-serif;
  color:var(--primary-blue-color);
} 

p{
  font-family: 'PT Sans', sans-serif;
}

span{
  font-family: 'Roboto', sans-serif;
}


  .container{
      max-width: calc(100% - 60px) !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
      
  }

  .main_wrapper{
    padding-top:100px;
  }

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 50px 0;
  overflow: hidden;
}

section .section-title h1{
    font-weight: 900;
    font-size: 48px;
    text-transform: uppercase; 
}

section .section-title{
  width: fit-content;
  padding-right :25px;
  position: relative;
  z-index: 1;
}

section .section-title::before{
  content: "";
  position: absolute;
  right: 0;
  top: 15%;
  height: 70%;
  width: 0;
  border-right: 3px solid ;
}



   /* Define responsive height for slider */
   @media (max-width: 768px) {
    .container{
      max-width: calc(100% - 10px) !important;
      
  }

  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
    .container{
      max-width: calc(100% - 30px) !important;
      
  }

  }
  
  @media (min-width: 1025px) {
    .container{
      max-width: calc(100% - 60px) !important;
      
  }

  }