#hero-slider .slick-slider {
    /* height: 70%; */
   }
   
  #hero-slider .slick-list{
   padding: 0px !important;
   }

  #hero-slider .slick-slider img{
       width:100%;
       /* height:72vh; */
       object-fit:fill;
   }

   #hero-slider .slick-dots{
    bottom:-100px;
   }

   #hero-slider .slick-dots li
   {
       position: relative;
       width: 20px;
       height: 20px;
       cursor: pointer;
   }
   #hero-slider .slick-dots li button
   {
       font-size: 0;
       line-height: 0;
       display: block;
       width: 20px;
       height: 20px;
       padding: 5px;
       cursor: pointer;
       color: transparent;
       border: 0;
       outline: none;
       background: transparent;
   }
   #hero-slider .slick-dots li button:hover,
   #hero-slider .slick-dots li button:focus
   {
       outline: none;
   }
   #hero-slider .slick-dots li button:hover:before,
   #hero-slider .slick-dots li button:focus:before
   {
       opacity: 1;
   }
   #hero-slider .slick-dots li button:before
   {
       font-family: 'slick';
       font-size: 10px;
       line-height: 20px;
   
       position: absolute;
       top: 0;
       left: 0;
   
       width: 20px;
       height: 20px;
   
       content: '•';
       text-align: center;
   
       color: var(--secondary-gray-color);
   
       -webkit-font-smoothing: antialiased;
       -moz-osx-font-smoothing: grayscale;
   }
   #hero-slider .slick-dots li.slick-active button:before
   {
       opacity: .75;
       color: var(--primary-yellow-color);
   }

/* Custom styles for slick slider arrows */
#hero-slider .slick-prev,
#hero-slider .slick-next {
  background: transparent; 
  color: #fff;
  z-index: 1; 
  border: none; 
}

#hero-slider .slick-prev::before,
#hero-slider .slick-next::before {
  font-family: "slick";
  font-size: 32px; 
}

#hero-slider .slick-prev {
  left: 10px; 
}

#hero-slider .slick-next {
  right: 15px; 
}

#hero-slider .slick-prev::before {
  content: '←'; 
}

#hero-slider .slick-next::before {
  content: '→'; 
}

#hero-slider .overlay-shadow {

  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}
#hero-slider .overlay {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
}

#hero-slider .text-container {
  text-align: left;
  margin-top: 40px;
  margin-left: 20px;
  color: #fff;
  z-index: 1;
  max-width: 75svw;
  text-wrap: wrap;
  padding: 40px;
}

#hero-slider .text-container p{
  font-family: 'PT Sans', sans-serif;
  font-size:16px;
}

#hero-slider .text-container h4{
  font-size: 30px;
  color: var(--primary-yellow-color);
  padding-bottom: 20px;
}


   /* .vertical-dots
   {
       right:10px;
       list-style: none;
       display: block;
       position: absolute;
       top: 40%;
       margin-top: -10px;
       margin-right: -30px;
       text-align: right;
   } */
   /* .vertical-dots li
   {
       position: relative;
       width: 20px;
       height: 20px;
       cursor: pointer;
   }
   .vertical-dots li button
   {
       font-size: 0;
       line-height: 0;
       display: block;
       width: 20px;
       height: 20px;
       padding: 5px;
       cursor: pointer;
       color: transparent;
       border: 0;
       outline: none;
       background: transparent;
   }
   .vertical-dots li button:hover,
   .vertical-dots li button:focus
   {
       outline: none;
   }
   .vertical-dots li button:hover:before,
   .vertical-dots li button:focus:before
   {
       opacity: 1;
   }
   .vertical-dots li button:before
   {
       font-family: 'slick';
       font-size: 10px;
       line-height: 20px;
   
       position: absolute;
       top: 0;
       left: 0;
   
       width: 20px;
       height: 20px;
   
       content: '•';
       text-align: center;
   
       color: var(--secondary-gray-color);
   
       -webkit-font-smoothing: antialiased;
       -moz-osx-font-smoothing: grayscale;
   }
   .vertical-dots li.slick-active button:before
   {
       opacity: .75;
       color: var(--primary-yellow-color);
   }
 */

 @media (max-width: 568px) {
 #hero-slider .text-container {
  padding: 5px;
  margin-top: 0px;
}

#hero-slider .text-container p{
  font-family: 'PT Sans', sans-serif;
  font-size:14px;
}

#hero-slider .text-container h4{
  font-size: 20px;
  padding-bottom: 0px;
}


 }

   /* Define responsive height for slider */
@media (max-width: 768px) {
    #hero-slider  .slider-wrapper {
      height: 40vh; /* Adjust height for smaller screens */
    }
    #hero-slider .slick-slider img{
        height: 40vh;
    }
    #hero-slider .text-container {
      margin-top: 20px;
    }
  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
    #hero-slider  .slider-wrapper {
      height: 60vh; /* Adjust height for medium screens */
    }
    #hero-slider .slick-slider img{
        height: 60vh;
    }
    #hero-slider .text-container {
      margin-top: 30px;
    }
  }
  
  @media (min-width: 1025px) {
    #hero-slider  .slider-wrapper {
      height: 80vh; /* Adjust height for larger screens */
    }
    #hero-slider .slick-slider img{
        height: 80vh;
    }
    #hero-slider .text-container {
      margin-top: 60px;
    }
  }