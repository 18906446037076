.add-form{
  
}

.add-form input{
    margin: 10px;
}

.add-form .row{
    margin-top: 20px;
}

#new-proj-form button{
    display: inline-block;
    margin: 10px;
}

.txt_note{
    color: var(--primary-yellow-color);
    font-weight: bold;
}

.red_text{
    color: red;
    font-family: 'PT Sans';
}

.green_text{
    color: green;
    font-family: 'PT Sans';
}

.yellow_text{
    color: var(--primary-yellow-color);
    font-family: 'PT Sans';
}


.add-form .submit-button{
background-color: var(--primary-blue-color);
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
    margin-left: 50px;
}

.add-form button{
    background-color: var(--secondary-light-gray-color);
        border: 1px solid black;
        padding: 10px 24px;
        color: black;
        transition: 0.4s;
    }

    .add-form textarea{
        width: 500px;
        height: 300px;
    }

   #admin-form .admin_overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        justify-content: center;
        align-items: center;
        z-index: 1000;
      }
      
     #admin-form .admin_loader {
        margin: 25%;
        margin-left:45%;
        border: 16px solid #f3f3f3;
        border-top: 16px solid #3498db;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        animation: spin 1s linear infinite;
        z-index: 1001; /* Ensure the loader is on top of the overlay */
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
      