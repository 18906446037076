#our_company{
 
        background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)),url(./light-gray-bg.jpeg);
        background-attachment: fixed;
        background-blend-mode: lighten;
        background-repeat: repeat-y;
        background-size: cover;
 
}

#our_company .section-title {
  color: var(--primary-blue-color);
  border: var(--primary-blue-color);
}

#our_company_hero{
    position: relative;
    height: 65vh;
    background-image: url(./plant-site.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white; 
    text-align: center;
    
}

#our_company_hero .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)); /* Adjust the alpha value for the desired darkness */
  }
  
  #our_company_hero .section-title{
    color: var(--primary-yellow-color);
    font-size: 65px;
  }

  #our_company section p{
    font-family: 'PT Sans', sans-serif;
  }

  .text-container {
    text-align: left;
   margin-top: 0;
    z-index: 1; 
    width: 50%;
  }

  .text-container p{
    font-family: 'PT Sans', sans-serif;
    font-size: large;
  }

  #bod .bod_images{
    margin:5px;
  }
  #bod .image_wrapper{
    min-width: 150px;
  }

  #bod .section-title{
    color: var(--primary-blue-color)
  }

  #bod .bod-text-wrapper{
    display: block;
    min-height: 500px;
  }


  #bod #bod_label{
    color: var(--primary-yellow-color);
    font-family: 'PT Sans';
  }

  #bod .bod_images{
      filter: grayscale(100%);
      transition: filter 0.3s ease-in-out;
      width: 200px;
      height: 300px;
    }
    
  #bod  img:hover {
      filter: grayscale(0);
  }

  #bod  .bod_selected_image{
    filter: grayscale(0);
  }
