#project_list .selected{
    background-color: var(--primary-blue-color);
    color: white;
}
#project_list .project{
border: 1px solid black;
}

#project_list{
    margin: 10px;
    margin-right: 30px;
}