

  div.gradient {
    height: 2px;
    border: none;
    border-radius: 6px;
    background:linear-gradient(
        90deg,
        rgba(0, 0, 0, 1) 0%,
        var(--primary-yellow-color) 31%,
        var(--primary-yellow-color) 71%,
        rgba(0, 0, 0, 1) 100%
      );
      display: block;
      margin-top: 3em;
      margin-bottom: 3em;
      margin-left: 0;
      margin-right: 0;
  }

  .on_hand_projects span{
    font-family: 'PT Sans', sans-serif;
  }

  .on_hand_projects p{
    font-family: 'PT Sans', sans-serif;
  }

  .yellow_txt{
    color: var(--primary-yellow-color);
    font-weight: bold;
  }

  .blue_txt{
    color: var(--primary-blue-color) ;
    font-weight: bold ;
  }