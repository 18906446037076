#contact {
    padding-bottom: 80px;
  }
  
  #contact{
    background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)),url(./light-gray-bg.jpeg);
    background-blend-mode: lighten;
    background-repeat: repeat-y;
    background-size: contain;
  }
  #contact h1{
    color: var(--primary-blue-color);
  }
  .contact{
    padding-bottom: 20px;;
  }
  .contact .info-box {
    text-align: center;
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6); 
    padding: 20px 0 0px 0;
    border-radius: 10px;
    color: var(--primary-blue-color);
    height: 100%;
   background-color: #fff;
  }
  
  .contact .info-box i {
    font-size: 32px;
    color: var(--primary-yellow-color);
    border-radius: 50%;
    padding: 8px;
    border: 2px dotted var(--primary-yellow-color);;
  }
  
  .contact .info-box h3 {
    font-size: 20px;
   
    font-weight: 700;
    margin: 10px 0;
  }
  
  .contact .info-box p {
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }

  .map-box{
    height: 50vh;
  }