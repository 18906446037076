/* Add this CSS to your styles file or component */

#admin-form .admin-form-tabs {
    display: inline-block;
    justify-content: space-around;
  }
  
  #admin-form .admin-form-link {
    cursor: pointer;
    display: inline-block;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 0.5px solid var(--primary-blue-color);
    background-color: #f1f1f1;
  }

  #admin-form div.admin-form-link{
    margin-right: 10px;
  }
  
  #admin-form .admin-form-link a {
    text-decoration: none;
    padding: 10px 20px;
    color: #333;
  }
  
  #admin-form .admin-form-link a:hover {
    background-color: var(--primary-blue-color);
    color: white !important;
  }
  
  #admin-form .admin-form-link a.active {
    background-color: var(--primary-blue-color);
    color: white !important;
  }
  
  #admin-form .form-section{
    border: 1px solid var(--primary-blue-color);
  }