#milestone-slider {
font-family: 'PT Sans', sans-serif;
text-align: center;
border-top: 1px solid var(--secondary-gray-color);
border-bottom: 1px solid var(--secondary-gray-color);
margin-right: 25px;
}

#milestone-slider .slick-slide{
    padding:20px;
}

#milestone-slider .slick-current{
    border-top: 2px solid var(--primary-blue-color);
    border-bottom: 2px solid var(--primary-blue-color)
}

#milestone-slider .slick-prev:before,#milestone-slider .slick-next:before{
    color:var(--primary-yellow-color);
    font-size: 25px !important;
}


#milestone-slider .milestone_year{
    color:var(--primary-blue-color);
    font-weight: 700;
}

#milestone-slider .milestone_project{
    color: #333;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
}

#milestone-slider .milestone_details{
    color: #333;
    font-family: 'Roboto', sans-serif;
}