
#home{
    background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)),url(./light-gray-bg.jpeg);
    background-attachment: fixed;
    background-blend-mode: lighten;
    background-repeat: repeat-y;
    background-size: cover;
}
#home h1{
    color: var(--primary-blue-color) !important;
    border-color: var(--primary-blue-color) !important;
}

#home .section-title::before{
    color: var(--primary-blue-color);
}

#hero{
    padding-top: 0px;
    padding-right: 20px !important;
}

.news_tag_line_main {
    width: 100%;
    background: #DEDEDE;
    display: inline-block;
}
.new_line {
    font-family: 'PT Sans', sans-serif;
    padding: 15px 20px;
    float: left;
    width: 110px;
}
.new_line .orange{
	line-height:15px;
}
.news_border_left {
    border-right: 3px solid #F17422;
    padding-right: 15px;
    font-family: 'PT Sans', sans-serif;
    color: var(--primary-blue-color);
}

.marquee_line {
    font-family: 'PT Sans', sans-serif;
    float: left;
    width: calc(100% - 110px);
    padding: 15px 0;
    color: 333;
}

#milestones{
   
}

#our_expertise p{
    font-family: 'PT Sans', sans-serif;
}

#our_expertise .photo_gallary img{
    height: 50%;
    width: 100%;
    object-fit: fill;
    padding-top: 10px;
    padding-bottom: 10px;
}

#our_expertise .photo_gallary{
    height: 100%;
}

#our_expertise #btn-readmore{
    background-color: var(--primary-yellow-color);
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
  }

  #our_expertise  #btn-readmore:hover{
    color: var(--primary-blue-color);
}

#expertise-text-block {
    height: fit-content;
}

#expertise-details.expandable-div {
    overflow: hidden;
    transition: height 0.5s ease;
  }
  
  /* Mobile styles */
  @media only screen and (max-width: 600px) {
    #expertise-details.expandable-div {
      height: 20%;
    }
    #expertise-details.expandable-div.expanded {
      height: 100%;
    }
  }
  
  /* Tablet styles */
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    #expertise-details.expandable-div {
      height: 20%;
    }
    #expertise-details.expandable-div.expanded {
      height: 100%;
    }
  }
  
  /* Desktop styles */
  @media only screen and (min-width: 1025px) {
    #expertise-details.expandable-div {
      height: 20%;
    }
    #expertise-details.expandable-div.expanded {
      height: 100%;
    }
  }

#our_philosophy {
    color: #fff ;
    /* background-color: var(--primary-blue-color); */
    background: url(./philosophy.webp);
    background-attachment: fixed;
    background-repeat: repeat-y;
    background-size: cover;
    font-family: 'PT Sans', sans-serif;
}
#our_philosophy .section-title h1{
    color: #fff !important;
    border-color: #fff !important;
}
#our_philosophy .section-title::before{
    border-color: #fff !important;
}
#our_philosophy .philosophy_container{
    margin-right: 10px;
    margin-bottom: 10px;
}
#our_philosophy .philosophy_container .philosophy_block div{
    border: #fff 1px solid;
    height: 100%;
    padding: 20px;
    font-family: 'PT Sans', sans-serif;
}
#our_philosophy .philosophy_container .philosophy_block{
    padding: 10px;
} 
#our_philosophy .philosophy_container .philosophy_block img{
    height: 80px;
    width: 80px;
    object-fit: cover;
}
#our_philosophy .philosophy_title_wrapper{
    padding: 20px;
    min-width: 200px;
}

.icon-40 { font-size: 40px; }

#our_clients {
    padding: 0px;
    box-shadow: 0 4px 15px -2px var(--secondary-gray-color);
    background-color: #fff;
}

#our_clients .clients_marquee_line{
    box-shadow: 0 4px 15px -2px var(--secondary-gray-color);
    display:inline-flex;
}
#our_clients .clients_marquee_line div{
    display: block;
    margin:30px;
}
#our_clients .clients_marquee_line img{
    height: 100px;
    width: 200px;
}

#prestigious_projects .proj_image {
    margin:30px;
    height: 450px;
    box-shadow: 0 4px 15px -2px var(--secondary-gray-color);

}
#prestigious_projects .proj_image img{
    object-fit: fill;
    width: 100%;
    height: 100%;
}

#prestigious_projects .proj_details {
    margin:30px;
}

#prestigious_projects .proj_details h3{ 
    font-family: 'PT Sans', sans-serif;
    color:var(--primary-blue-color);
}


#our_presence .map_wrapper{
    margin:30px;
    height: 450px;
    box-shadow: 0 4px 15px -2px var(--secondary-gray-color);
}

#our_presence .address_wrapper {
    margin:30px;
}

#our_presence .address_wrapper h3{ 
    font-family: 'PT Sans', sans-serif;
    color:var(--primary-blue-color);
}

#our_presence .address_wrapper span{
    font-family: 'PT Sans', sans-serif;
    color:var(--primary-blue-color);
}
#our_presence .address_wrapper a{
    font-family: 'PT Sans', sans-serif;
    color: var(--primary-yellow-color);
    text-decoration: none;
}

#our_presence .address_wrapper a:hover{
    text-decoration: underline;
    cursor: pointer;
}

