.image-gallery {
  position: relative;
}

.image-gallery .gallery-item {
  cursor: pointer;
}

.image-gallery .slick-slider {
  width: 80%;
  margin: 0 auto; /* Center the slider */
}

.image-gallery .slick-slide img {
  width: 100%; /* Ensure images take 100% width of the slider */
  max-height: auto; /* Adjust as needed to maintain image aspect ratio */
}

.image-gallery .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.image-gallery .carousel-container {
  position: relative;
  width: 100%;
}

.image-gallery .carousel-item {
  text-align: center;
}

.image-gallery .carousel-item img {
  max-width: 100%;
  max-height: 100vh;
}

.image-gallery .close-btn {
  position: absolute;
  top: 5px;
  right: 30px;
  background-color: transparent;
  color: var(--secondary-light-gray-color);
  border: none;
  font-size: 36px;
  font-weight: 200;
  cursor: pointer;
}
.image-gallery .slick-prev:before,.image-gallery .slick-next:before{
  color:var(--primary-yellow-color);
  
}
.image-gallery .slick-prev,.image-gallery .slick-next{

line-height: 2px;
  
}