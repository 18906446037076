#footer{
    background: url(./footer-bg.jpg);
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
}


/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact-form .form-control{
    margin: 5px;
}

.links-block{
    padding-top: 60px;
    padding-left: 60px;
}

.links-block h3{
    font-size: 20px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
}

.links-block ul{
    list-style-type: none;
    padding: 0;
}

.links-block ul li a {
    text-decoration: none;
    color: #fff;
}

.links-block ul li a:hover {
    text-decoration: none;
    color: var(--primary-yellow-color);
}

#btn-submit{
    background-color: var(--primary-yellow-color);
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
    width: 100%;
    font-family: 'PT Sans', sans-serif;
    border-radius: 5px;
}
#btn-submit:hover{
    color: var(--primary-blue-color);
}

.social-links a{
    font-size: 18px;
    display: inline-block;
    background: var(--primary-yellow-color);
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

.social-links a:hover{
    color: var(--primary-blue-color);
}

#copyrights{
    color: var(--primary-yellow-color);
    text-align: center;
    padding: 0px;
    font-family: 'PT Sans', sans-serif;
}