/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
  
   #header{
    background: var(--secondary-light-gray-color);
    padding: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
    box-shadow: 0 4px 15px -2px var(--secondary-gray-color);
  }

    .ml-auto {
    margin-left: auto !important;
  }

  .navbar img{
    height: 60px;
  }

  #basic-navbar-nav a{
    display: block;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    transition: 0.3s;
    text-decoration: none;
    color: var(--primary-blue-color);
  }

  #basic-navbar-nav a:hover {
    /* transform: scale(1.1); Adjust the scale factor as desired */
    color: var(--primary-yellow-color);
  }

  #basic-navbar-nav a::before {
     transform: scaleX(0);
  transition: transform 0.3s ease;
  }

  .active {
    color:  var(--primary-yellow-color) !important;
  }
 
  #btn-contact{
    background-color: var(--primary-yellow-color);
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
    margin-top:-10px;
  }

  #btn-contact:hover{
    color: var(--primary-blue-color);
}

div.active.nav-item.dropdown a#basic-nav-dropdown{
  text-decoration: none;
  color: var(--primary-yellow-color) !important;
  transform: scale(1.1);
  
}

.nav-item .dropdown-menu .dropdown-item{
  color: var(--primary-blue-color);
  margin-left: 0px !important;
  padding-left: 25px !important;
  font-size: 16px;
}

.nav-item .dropdown-menu .dropdown-item.active,
.nav-item .dropdown-menu .dropdown-item:active {
  color: var(--primary-yellow-color) !important;
  transform: scale(1.1);
  background-color: transparent;
}

.dropdown-menu {
  transition: all linear 0.3s;
  border-radius: 0px !important;
  margin-right: 2px !important;
  width: 100% !important;
  box-shadow: 0 4px 15px -2px var(--secondary-gray-color) !important;
  margin-top: 0px !important;
  font-size: 16px;
}
#basic-navbar-nav .dropdown a{
  font-size: 16px;
  font-family: 'PT Sans', sans-serif;;
}

.nav-item.dropdown{
  padding-top: 0px !important;
}

.nav-item .a{
  vertical-align: middle !important;
}

 /* Define responsive height for slider */
 @media (max-width: 468px) {
  #header{
    padding: 0px !important;
}

}