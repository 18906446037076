#basic-navbar-subnav a{
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    transition: 0.3s;
    text-decoration: none;
    color: var(--secondary-light-gray-color);
    text-transform: uppercase; 
    display: block;
    line-height: 3em;
    height: 4em;
    background-color: #888;
    padding-top: 5px;
  }

  #basic-navbar-subnav .nav-list a:after{
    content: "";
    position: relative;
    right: 0;
    bottom: 35px;
    height: 60%;
    width: 1px;
    border-right: 1px solid var(--secondary-light-gray-color);
    display: block;
    align-items: center;
  }

/* Remove right border from the last anchor element */
.nav-list a:last-child::after {
  border: none;
}
.nav-list a:first-child::before {
  border: none;
}

  #basic-navbar-subnav a.active{
    background-color: var(--primary-yellow-color) !important;
    color: #fff !important;
  }
  #basic-navbar-subnav a:hover{
    background-color: var(--primary-yellow-color);
    color: #fff;
  }

  #basic-navbar-subnav {
    background-color: var(--secondary-light-gray-color);
  }
  #basic-navbar-subnav .nav-list{
    display: flex;
    justify-content: space-around; /* Spread items evenly */
    margin: 0;
    padding: 0;
    width: 100%;
  }
  
  #project_submenu{
    text-align: center;

  }

  #project_submenu nav#sub-header.navbar{
    padding: 0px !important;
    margin: 0px !important;
  }

  #project_submenu .navbar-toggler {
    background-color: var(--primary-yellow-color) !important;
    opacity: 1;
  }
  #project_submenu .navbar-toggler-icon{
    background-color: var(--primary-yellow-color) !important;
    opacity: 1;
  }