.image-animation-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 5000; /* Adjust as needed, ensure it's a higher value than other components */
    
  }
  
  #image-animation-container.hidden {
    visibility: hidden;
    opacity: 0;
  }
  
  .image {
    position: absolute;
    top: 0;
    height: 100vh;
    background-size: cover;
    transition: transform 5s ease-out;
  }
  
  .left {
    background-image: url('./logo-animation_left_rectangle.webp'); /* Replace with your left image */
    left: 0;
    width: 50vw;
  }
  
  .right {
    background-image: url('./logo-animation_right_rectangle.webp'); /* Replace with your right image */
    left: 30%;
    width: 100vw;
    object-fit:contain;
    z-index: 5100;
  }
  
  .animate-images .left{
      transform: translateX(-100vw);
    }
  
    .animate-images .right {
      transform: translateX(100vw);
    }
  
 @media (max-width: 375px) {
      .right {
          left: -60px !important;
          width: 100vw;
      }
  
     }

     @media (max-width: 500px) {
      .right {
          left: -30px !important;
          width: 100vw;
      }
  
     }

  @media (max-width: 768px) {
    .right {
        left: 0px;
        width: 100vw;
    }

   }

   @media (max-width: 968px) {
    .right {
        left: 10px;
        width: -webkit-fill-available;
    }
}